import { Component, OnInit } from "@angular/core";
import {
  Customer,
  Form,
  AdministrationService,
  InventoryManagementService,
  LoggingService,
  Material,
  Container,
  User,
  AuthenticationService,
  FormManagementService,
} from "process-smart-ng6-lib";
import { CustomDownloadServiceService } from "src/app/_services/custom-download-service.service";
import { SharedService } from "src/app/_services/shared.service";
import { saveAs } from "file-saver";

@Component({
  selector: "app-view-stock-by-truck",
  templateUrl: "./view-stock-by-truck.component.html",
  styleUrls: ["./view-stock-by-truck.component.css"],
})
export class ViewStockByTruckComponent implements OnInit {
  partsList: Array<any>;
  truckList: Array<Container>;
  userslist: Array<User>;
  typeId = "54";
  deptId = "1";
  totalCost: string;
  monthChoices: Array<any>;
  chosenDate: string;
  chosenMonth: string;
  matDict: any;
  keyArr: Array<any>;
  chosenSupervisorId: string;

  constructor(
    private aus: AuthenticationService,
    private fms: FormManagementService,
    private ims: InventoryManagementService,
    private cds: CustomDownloadServiceService,
    private ssv: SharedService
  ) {}

  ngOnInit() {
    this.populateTrucks();
    this.populateMaterials();
    this.populateUsers();
    this.populateMonthChoices();
    this.totalCost = "0";
  }

  populateMonthChoices() {
    let curDate = new Date();
    curDate.setDate(1);
    let prevDate = new Date();
    prevDate.setDate(1);
    prevDate.setMonth(prevDate.getMonth() - 1);
    let prev2Date = new Date();
    prev2Date.setDate(1);
    prev2Date.setMonth(prev2Date.getMonth() - 2);
    this.monthChoices = [prev2Date, prevDate, curDate];
  }

  populateMaterials() {
    this.ims.getMaterials(this.deptId).subscribe((itemsList) => {
      this.partsList = itemsList;
    });
  }

  populateUsers() {
    this.aus.getUsers().subscribe((itemsList) => {
      this.userslist = itemsList;
    });
  }

  populateTrucks() {
    this.ims.getContainers(this.deptId).subscribe((itemsList) => {
      this.truckList = itemsList;
    });
  }

  populateItems() {
    if (!this.chosenDate || !this.chosenSupervisorId) return;
    let lastDayOfMonth = new Date(
      +this.chosenDate.substring(0, 4),
      +this.chosenDate.substring(5, 7),
      0
    );
    const l = this.ssv.toHtmlDateStr(lastDayOfMonth);

    this.fms
      .getFormsByDate(this.typeId, this.chosenDate, l, this.chosenSupervisorId)
      .subscribe((itemsList) => {
        this.matDict = {};
        this.keyArr = [];
        if (!itemsList) {
          return;
        }
        console.log("Driver is " + this.chosenSupervisorId);

        let materialList = itemsList.map((x) => x.inputTables.materials);
        //Iterate over array of arrays of materials
        materialList.forEach((y) => {
          //Operate on this array of materials
          y.forEach((x) => {
            if (this.matDict[x.material_id]) {
              this.matDict[x.material_id].quantity =
                "" + (+this.matDict[x.material_id].quantity + +x.quantity);
            } else {
              this.keyArr.push(x.material_id);
              this.matDict[x.material_id] = x;
            }
          });
        });

        let totalCost = 0;
        this.keyArr.forEach((i) => {
          let v = this.matDict[i];
          let mat = this.partsList.find((x) => x.id == v.material_id);
          if (mat) {
            v.part_num = mat.part_num;
            v.description = mat.description;
            v.unit = mat.unit;
            v.price = mat.price;
            v.total = +v.quantity * +mat.price;
            totalCost += +v.total;
          }
        });
        this.totalCost = "" + totalCost;
      });
  }

  onDownload() {
    if (!this.chosenDate || !this.chosenSupervisorId) return;
    let lastDayOfMonth = new Date(
      +this.chosenDate.substring(0, 4),
      +this.chosenDate.substring(5, 7),
      0
    );
    const l = this.ssv.toHtmlDateStr(lastDayOfMonth);

    this.cds
      .downloadStockTransferMaterialUsage(
        this.chosenDate,
        l,
        this.chosenSupervisorId
      )
      .subscribe(
        (res) => {
          saveAs(res, "MonthlyStockTransfer.xls", {
            type: "application/vnd.ms-excel",
          });
        },
        (error) => {
          // Display error message
          this.ssv.showError("Error: Unable to download usage history!");
        }
      );
  }

  onDownloadMonthlySummary() {
    if (!this.chosenMonth) return;
    let month = this.chosenMonth.split("-")[0];
    let year = this.chosenMonth.split("-")[1];
    this.cds.downloadStockTransferMonthlySummary(month, year).subscribe(
      (res) => {
        saveAs(res, "MonthlyStockTransferSummary.xls", {
          type: "application/vnd.ms-excel",
        });
      },
      (error) => {
        // Display error message
        this.ssv.showError(
          "Error: Unable to download monthly stock transfer summary!"
        );
      }
    );
  }
}
